import React, { useState } from 'react';

const ReviewTextBox = ({ review, onReviewChange, intl }) => {
  return (
    <div className="review-textbox">
      <textarea
        value={review}
        onChange={(e) => onReviewChange(e.target.value)}
        placeholder={intl.formatMessage({ id: 'ReviewModal.placeholder' })}
      />
    </div>
  );
};

export default ReviewTextBox;
